import React, { useContext } from 'react'
import { TaskBar, List } from '@react95/core'
import styled from 'styled-components'

const Link = styled.a`
    text-decoration: none;
    color: inherit;
`
const EasterEggLink = styled.a`
    text-decoration: none;
    color: rgba(255, 255, 255, 0.1); // Make it almost invisible
    &:hover {
        color: rgba(255, 255, 255, 0.5); // Make it slightly visible on hover for those who discover it
    }
`;

function Taskbar() {
    return (
        <TaskBar
            list={
                <List>
                    <List.Item className="pointer">
                        <EasterEggLink href="mailto:operationmoonreach@Gmail.com?subject=I found the easter egg">Oh hi</EasterEggLink>
                    </List.Item>


                </List>
            }
        />
    )
}

export default Taskbar
