import React from 'react'
export default class DataService {
  _data = {
    items: [
      {
        id: 'about',
        name: 'About.txt',
        icon: 'info_bubble',
        content: {
          paragraphs: [
            "Uniting Aliens and Humans in Shared Existence.",
            "Welcome to Alien Inbox, where we bridge the gap between humanity and potential extraterrestrial civilizations. Our mission is to establish protocols for contact with alien beings, safeguarding both our newfound friends and humanity.",
            "As a pioneering B2A (Business to Alien) SaaS company, we develop open software designed with infinite possibilities in mind. Our goal is to facilitate seamless transitions for aliens embracing life on Earth.",
            "At the heart of our mission is the Lunar Station Checkpoint, a dedicated facility for educating our future alien counterparts about what it means to be human. This initiative is crucial for nurturing a harmonious relationship and preparing for potential alien encounters.",
            "Today, you can send a message, a simple radio wave, to potentially connect with an alien friend. This project marks a small but significant step toward the ultimate goal of fostering peaceful human-alien relations. Will you join us on this extraordinary journey?",
          ],
        },
      },
      {
        id: 'projects',
        name: 'Projects.txt',
        icon: 'explore',
        content: {
          projects: [

            {
              title: 'Alien Inbox',
              description: `Overview:
              Alien Inbox is a messaging platform that enables users to send messages via radio waves to potential extraterrestrial civilizations. It is designed to reach out to the unknown, fostering connections and mutual understanding with beings beyond our world.
              
              Key Features:
              Hyper-Intuitive Interface:
              - A supernaturally user-friendly interface that enables seamless composition and transmission of messages, allowing every user to become a cosmic communicator.
              - Quantum Encrypted Messaging: Ultra-secure, employing quantum encryption technologies to protect messages, ensuring they are received as intended by potential extraterrestrial entities.
              - Polyglot Protocol Support: Employs advanced linguistic algorithms, enabling communication in countless languages and potentially decipherable by extraterrestrial intelligences.
              - Cosmic Community Forum: A pulsating hub where interstellar communicators converge to share their cosmic musings, experiences, and aspirations, forging a community of like-minded celestial ambassadors.
              `
            },    
            {
              title: 'Operation Moon Base: A Beacon of Intergalactic Unit',
              description: `Overview:
              Situated in the tranquil silhouettes of the Moon's craters, Lunar Harmony Base serves as a pioneering outpost and a symbol of the collaborative spirit between humans and extraterrestrial entities. It is a sanctuary where knowledge, culture, and innovations are exchanged, fostering mutual growth and understanding. This celestial haven is equipped with advanced technologies and facilities, ensuring a harmonious living environment for all inhabitants, regardless of their planetary origin. Lunar Harmony Base is not just a settlement; it is a testament to the boundless possibilities that arise when diverse civilizations unite in the pursuit of common goals and shared dreams.
              
              Key Features:
              - Intergalactic Collaboration Hub: A space dedicated to fostering dialogue and cooperation between humans and extraterrestrial beings, focusing on shared learning and development.
              - Advanced Living Facilities: Equipped with state-of-the-art living quarters, ensuring the well-being and comfort of all inhabitants, terrestrial and extraterrestrial.
              - Scientific Research Center: A facility designed for cutting-edge research and innovation, promoting the exchange of knowledge and technology between diverse civilizations.
              - Cultural Exchange Pavilion: A space where the richness of various cultures is celebrated and shared, encouraging mutual respect and understanding.
              - Sustainable Ecosystems: Integration of advanced ecological systems to sustain diverse forms of life, facilitating a balanced and harmonious existence.
              - Security and Peacekeeping Unit: A dedicated team and system to maintain peace and order, ensuring the safety and harmony of all base inhabitants.

              Development Phases:
              - 1. Quantum Harmonization and Schematic Synthesis
              - 2. Transdimensional Resource Integration and Morphogenic Construction
              - 3. Biocosmic Synchronization and Environmental Enmeshment:
              - 4. Multiversal Synergy Calibration and Operational Actualization:
              `
            },              
          ],
        },
      },
      // {
      //   id: 'skills',
      //   name: 'Skills.txt',
      //   icon: 'progman_11',
      //   content: {
      //     hard: [
      //       {
      //         name: 'Python',
      //         progress: 100,
      //       },
      //       {
      //         name: 'Java',
      //         progress: 100,
      //       },
      //       {
      //         name: 'Swift/SwiftUI',
      //         progress: 100,
      //       },
      //       {
      //         name: 'Javascript',
      //         progress: 80,
      //       },
      //       {
      //         name: 'HTML/CSS',
      //         progress: 90,
      //       },
      //       {
      //         name: 'Node.js',
      //         progress: 90,
      //       },
      //       {
      //         name: 'Typescript',
      //         progress: 90,
      //       },
      //       {
      //         name: 'React',
      //         progress: 80,
      //       },
      //       {
      //         name: 'SQL',
      //         progress: 70,
      //       },
      //       {
      //         name: 'Pandas',
      //         progress: 70,
      //       },
      //       {
      //         name: 'NumPy',
      //         progress: 80,
      //       },
      //       {
      //         name: 'SpaCy',
      //         progress: 70,
      //       },
      //       {
      //         name: 'Pytorch',
      //         progress: 70,
      //       },
      //     ],
      //     soft: 'Entrepreneurship, Leadership, Creative Problem Solving, Analytical Thinking, Emotional Intelligence, Conflict Management',
      //   },
      // },
      {
        id: 'sponsor',
        name: 'Sponsor.txt',
        icon: 'faxcover_140',
      },
      {
        id: 'contact',
        name: 'Contact.txt',
        icon: 'inetcfg_2301',
        content: {
          emailText:
            'Send me an email @ ',
          email: 'operationmoonreach@gmail.com',
          socialText: 'Or reach out below:',
          social: [
            {
              name: 'FaLink',
              link: '',
            },
            // {
            //   name: 'FaTwitter',
            //   link: 'https://twitter.com/declangessel_/',
            // },
            // {
            //   name: 'FaLinkedin',
            //   link: 'https://www.linkedin.com/in/declangessel/',
            // },
            // {
            //   name: 'FaGithub',
            //   link: 'https://github.com/declangessel',
            // },
            // {
            //   name: 'FaInstagram',
            //   link: 'https://www.instagram.com/declangessel/',
            // },
            // {
            //   name: 'FaSpotify',
            //   link: 'https://open.spotify.com/user/dghrnim2uztw50sl6jwa4lgez?si=9beefce7d7a24733/',
            // },

          ],
        },
      },
    ],
  };

  getItems() {
    return this._data.items.map(({ id, name, icon }) => ({ id, name, icon }));
  }

  getItem(id) {
    return this._data.items.find((x) => x.id === id);
  }

  getProjectInfo() {
    return {
      projectRepo: this._data.projectRepo,
      react95Repo: this._data.react95Repo,
    };
  }
}
