import React, { useState } from 'react'
import styled from 'styled-components'
import { FaLinkedin, FaGithub, FaInstagram, FaTwitter, FaSpotify } from 'react-icons/fa'
// import { Button, TextField } from 'react95';
import { List, ListItem, TextField, Button } from '@react95/core'


const Icons = { FaLinkedin, FaGithub, FaInstagram, FaTwitter, FaSpotify };


const StyledItem = styled.li`
    display: inline;
    font-size: 20px;
    margin-left: 10px;
`

const StyledLink = styled.a`
    color: black;
    display: flex;
    align-items: center;

`

const StyledInput = styled.input`
    margin: 10px 0;
    padding: 10px;
    width: 100%;
`

const StyledButton = styled.button`
    padding: 10px 20px;
    cursor: pointer;
`

function Contact({ content }) {
    const { emailText} = content;
    const mailto = `mailto:${email}`;

    const [message, setMessage] = useState('');
    const email = 'operationmoonreach@gmail.com';

    const handleSendEmail = () => {
        const subject = "ALIEN OUTMAIL REQUEST";
        const body = `ALIEN OUTMAIL REQUEST\n---------------------------\n\nMESSAGE\n---------------------------\n${message}`;
        window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    }
    return (
        <div>
            <h2>Send your message to space</h2>
            <StyledInput 
                type="text" 
                value={message} 
                onChange={(e) => setMessage(e.target.value)} 
                placeholder="Type your message here"
            />
            <StyledButton onClick={handleSendEmail}>Send Email</StyledButton>
            {/* <TextField 
                value={message} 
                onChange={(e) => setMessage(e.target.value)} 
                placeholder="Type your message here"
            />
            <Button onClick={handleSendEmail}>Send Email</Button> */}
{/* 
            <TextField 
                value={message} 
                onChange={(e) => setMessage(e.target.value)} 
                placeholder="Type your message here"
            />
            <Button onClick={handleSendEmail}>Send Email</Button> */}
        </div>
    )
}

export default Contact
