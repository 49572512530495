import React from 'react'

function About({ content }) {
    const { paragraphs } = content;
    return (
        <div>
            <h2>Alien Inbox</h2>
            {
                paragraphs.map((p, i) => (
                    <p key={i}>{p}</p>
                ))
            }
            <hr />
            <br />
            Learn more:
            <br />
            <a href="" target="_blank" rel="noopener noreferrer">Join Us</a>
            <br />
            <a href="" target="_blank" rel="noopener noreferrer">Contact Us</a>
            <br />

            <iframe 
                width="420" 
                height="236" 
                src="https://www.youtube.com/embed/4idgYzm1kNc" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen>
            </iframe>
            {/* <a href="https://www.instagram.com/berkeleyaerotech" target="_blank" rel="noopener noreferrer">Instagram</a> */}
            {/* <br />
            <a href="https://github.com/declangessel" target="_blank" rel="noopener noreferrer">GitHub</a>
            <br />
            <a href="https://www.instagram.com/declangessel/" target="_blank" rel="noopener noreferrer">Instagram</a>
            <br /> */}
        </div>
    )
}

export default About
