import React from 'react'



function Sponsor({ content }) {
    return (
        <div>
            <h2>Help support Alien Inbox</h2>

            <h4>
                click <a href="" target="_blank" rel="noopener noreferrer">here</a>
            </h4>
            <img src="https://25.media.tumblr.com/tumblr_lvirodvkSz1qg39ewo1_500.gif" alt="Photo gif" style={{ width: '70%' }}>
            </img>
        </div>
    )

}

export default Sponsor
